import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.tsx";
import { graphql } from 'gatsby';
export const webDevOathQuery = graphql`
  query WebDevOathQuery($pagePath: String!) {
    mdx(frontmatter: { path: { eq: $pagePath } }) {
      frontmatter {
        path
        title
        tags
        canonical_url
        tweet
        date(formatString: "MMMM DD, YYYY")
      }
      timeToRead
      excerpt
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  webDevOathQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`For the longest time within the medical profession, new physicians have taken the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Hippocratic_Oath"
      }}>{`Hippocratic Oath`}</a>{` (or some variation of it) in which they swear to uphold a certain ethical standard. With the great responsibility resting on these individuals, it makes total sense to do this.`}</p>
    <p>{`As developers, our actions can sometimes have massive impact on other people's lives, but yet ethics is not something discussed much in software development circles.`}</p>
    <p>{`What follows is an adaptation of the Hippocratic Oath, but for web developers:`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`I swear to fulfill, to the best of my ability and judgment, this covenant:`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`I will respect the hard-won scientific gains of those developer giants whose blogs I've read, and gladly share such knowledge as is mine with those newbies who are to follow.`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`I will apply, for the benefit of all project stakeholders, all linting rules that are required, avoiding those twin traps of over-engineering and premature optimizations.`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`I will remember that there is art to web development as well as science, and that warmth, sympathy, and continuous learning may outweigh the developer's current framework preference or build tools.`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`I will not be ashamed to say "I know not," nor will I fail to create a Stack Overflow question when the skills of another are needed for a project's successful outcome.`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`I will respect the privacy settings of my end users' browsers, for their logins to my app are not disclosed to me to insecurely store in plain text. Most especially must I tread with care in matters of code reviews. If it is given me to approve a PR, all thanks. But it may also be within my power to decline the PR; this awesome responsibility must be faced with great humbleness and awareness of my own code under review. Above all, I must encourage diversity.`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`I will remember that I do not style a button, implement an algorithm, but provide a digital experience, whose outcome may affect my users' family and mental stability. My responsibility includes these related problems, if I am to care adequately for my craft.`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`I will prevent bugs whenever I can, for continuous improvement is preferable to rewrites.`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`I will remember that I remain a member of society, with special obligations to all my fellow nerds, those sound of mind and body as well as those suffering from imposter syndrome.`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`If I do not violate this oath, may I enjoy fast internet and cross-browser consistency, respected while I code and remembered with affection when I become a project manager. May I always develop so as to preserve my end user's valuable mobile data and may I long experience the joy of coding apps that are accessible as the open web ought to be.`}</em></p>
    </blockquote>
    <p>{`In all seriousness, as a developer, next time you implement a new feature, create a GitHub issue, write a blog post or deliver a meetup talk, consider the impact of your actions on other people. Behind the code, there is a person.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      